import tw from 'twin.macro'
import '../../../App.css'
import { useState } from 'react'
import { useEffect } from 'react'
import { Header, SingleTicketView, Ticket } from '../components'
import { getVenueInfo } from '../utility'
import { useNavigate, useParams } from 'react-router-dom'

interface VenueInfo {}

export const DashboardRoute = () => {
  const params = useParams()
  const navigate = useNavigate()
  const [venueInfo, setVenueInfo] = useState<VenueInfo>({})
  const [loading, setLoading] = useState(true)

  const [onlyTicket, setOnlyTicket] = useState(false)

  //debugp
  const fetchVenueInfo = async () => {
    console.log('Got Info')
    const result = await getVenueInfo(params.domain)
    setVenueInfo(result)
    console.log('Got Info')
    console.log(result)
    console.log(params)
    if (params.ticket !== 'overview') {
      setOnlyTicket(true)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (params.domain) {
      fetchVenueInfo()
      setInterval(() => fetchVenueInfo(), 60000)
    } else {
      navigate('/configure')
    }
  }, [params])

  if (loading) {
    return (
      <div>
        <p>Loading</p>
      </div>
    )
  }

  return (
    <div tw="w-screen h-screen flex justify-content[flex-start] flex-direction[column] background-color[#F0F2F4] overflow-hidden">
      <Header venue={venueInfo} />
      {!onlyTicket &&
        venueInfo?.liveDashboard?.tickets?.map(ticket => {
          return <Ticket ticket={ticket} venueId={venueInfo?.id} />
        })}
      {onlyTicket && (
        <SingleTicketView venueInfo={venueInfo} ticketId={params.ticket} />
      )}
    </div>
  )
}
