import tw from 'twin.macro'
import { useState } from 'react'
import { useEffect } from 'react'
import { CurrentSlot } from './current-slot'
import { EmptySlot } from './empty-slot'
import { Slot } from './slot'
import { ViewBoardsIcon } from '@heroicons/react/solid'
import { fetchAvailability } from '../utility'
import { useNavigate, useLocation } from 'react-router-dom'

export const Ticket = ({ ticket, venueId }) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [ticketInfo, setTicketInfo] = useState([])
  const [currentSlot, setCurrentSlot] = useState()
  const [isFullDay, setFullDay] = useState(false)

  const fetchTicketInfo = async (venueId, ticketId) => {
    const { isFullDay, remainingSlots, currentSlot } = await fetchAvailability(
      venueId,
      ticketId,
    )
    setTicketInfo(remainingSlots)
    setCurrentSlot(currentSlot)
    setFullDay(isFullDay)
  }

  useEffect(() => {
    fetchTicketInfo(venueId, ticket.id)
  }, [ticket, venueId])

  const show = ticketId => {
    let currentPath = pathname.replace('overview', '')
    navigate(currentPath + ticketId)
  }

  return (
    <div tw="p-4 height[30%] border-radius[24px] flex-row flex ml-10 mr-10 max-width[300px]">
      <a
        onClick={() => show(ticket?.id)}
        tw="shadow-lg min-width[250px] border-right-width[2px]  bg-white border-radius[24px] flex flex-col p-4 justify-between cursor-pointer"
      >
        <div>
          {ticket?.image ? (
            <img
              src={ticket?.image}
              tw="bg-blueGray-200 width[100%] height[150px] border-radius[12px] object-cover"
            />
          ) : (
            <div tw="background-color[#040FD9] flex justify-center items-center width[100%] height[150px] border-radius[12px] object-cover">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                tw="h-24 w-24"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                />
              </svg>
            </div>
          )}

          <div>
            <p tw="inline-block color[#334155] text-2xl mt-2 font-family[bold]">
              {ticket.name}
            </p>
            <div tw="flex flex-row items-center">
              <ViewBoardsIcon tw="h-6 w-6 fill[#94A3B8]" aria-hidden="true" />
              <p tw="inline-block py-2 px-0.5 border border-transparent rounded-sm color[#94A3B8] text-sm text-base font-family[light]">
                {ticketInfo?.slots?.length} Open Slots
              </p>
            </div>
          </div>
        </div>
        <div>
          <div tw="flex flex-row">
            {ticketInfo?.available ? (
              <span tw="animate-pulse px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full flex flex-row items-center ">
                <div tw="bg-green-500 height[15px] width[15px] border-radius[8px] mr-2 border-default-green-300 border-width[3px]"></div>
                <p tw="inline-block border border-transparent text-green-700 rounded-md text-sm font-family[regular]">
                  Open
                </p>
              </span>
            ) : (
              <span tw="px-2 py-0.5 text-red-800 text-xs font-medium bg-red-100 rounded-full flex flex-row items-center ">
                <div tw="animate-pulse bg-red-500 height[15px] width[15px] border-radius[8px] mr-2 border-red-300 border-width[3px]"></div>
                <p tw="inline-block border border-transparent rounded-md color[#94A3B8] text-sm font-family[light]">
                  CLOSED
                </p>
              </span>
            )}
          </div>
        </div>
      </a>
      {currentSlot ? (
        <CurrentSlot fullday={isFullDay} slot={currentSlot} />
      ) : (
        <EmptySlot />
      )}
      {!isFullDay && (
        <div tw="shadow-lg height[100%]  bg-white border-radius[24px] flex justify-start flex-row items-center">
          {ticketInfo?.slots?.map(slot => {
            return <Slot slot={slot} />
          })}
        </div>
      )}
    </div>
  )
}
