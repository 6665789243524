import dayjs from 'dayjs'

// Fetches information for the venue from booking-page/api/store.json

export const getVenueInfo = async domain => {
  return new Promise((res, rej) => {
    var requestOptions: { method: 'GET' | 'POST'; redirect: RequestRedirect } =
      {
        method: 'GET',
        redirect: 'follow',
      }

    fetch(
      `https://${domain}.ticketbrosandbox.com/api/store.json`,
      requestOptions,
    )
      .then(response => response.text())
      .then(result => {
        result = JSON.parse(result)
        res(result)
      })
      .catch(error => {
        console.log(error)
        rej('error', error)
      })
  })
}

//Finds the current-slot in a tickets slots

export const findCurrentSlot = data => {
  const now = dayjs()
  const remainingSlots = []
  let currentSlot
  data.slots.map(slot => {
    if (now.diff(dayjs(slot.end)) < 0) {
      if (now.diff(dayjs(slot.start)) > 0) {
        currentSlot = slot
      } else {
        remainingSlots.push(slot)
      }
    }
  })
  data.slots = remainingSlots
  return { remainingSlots: data, currentSlot: currentSlot }
}

//Fetches the availability of a sprecific ticket of a venue

export const fetchAvailability = (venueId, ticketId) => {
  console.log('Fetch-Availability')
  return new Promise((res, rej) => {
    var requestOptions: { method: 'GET' | 'POST'; redirect: RequestRedirect } =
      {
        method: 'GET',
        redirect: 'follow',
      }

    fetch(
      `https://booking.ticketbrosandbox.com/venue/${venueId}/ticket/${ticketId}/availability?d=${dayjs().format(
        'YYYY-MM-DD',
      )}`,
      requestOptions,
    )
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result)
        const returnType = { isFullDay: false }
        if (data.slots.filter(slot => slot.isFullDay).length !== 0) {
          returnType.isFullDay = true
        }
        const { remainingSlots, currentSlot } = findCurrentSlot(data)
        returnType.remainingSlots = remainingSlots
        returnType.currentSlot = currentSlot
        res(returnType)
      })
      .catch(error => rej('error', error))
  })
}
