import tw from 'twin.macro'

export const EmptySlot = () => {
  return (
    <div tw=" shadow-lg color[#475569] pl-8 pr-8 height[100%] flex flex-col items-center font-family[Inter] bg-white border-radius[24px]">
      <span tw="width[100px] height[10px] relative top[0px] border-bottom-left-radius[5px] border-bottom-right-radius[5px] background-color[#E3E8EF]"></span>
      <p tw="inline-block border border-transparent rounded-md  text-lg font-family[bold] mt-2 color[#E3E8EF]">
        Now
      </p>
      <div tw="width[200px] height[80%] border-radius[12px] border-2 flex flex-col justify-end border-dashed"></div>
      <div tw="flex flex-row items-end justify-between mt-3 mb-3 height[20px]"></div>
    </div>
  )
}
