import { ConfigureRoute, DashboardRoute } from './features/dashboard'
import { Navigate, Route, Routes } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/configure" element={<ConfigureRoute />} />
        <Route path="/dashboard/:domain/:ticket" element={<DashboardRoute />} />
        <Route path="*" element={<Navigate replace to={'/configure'} />} />
      </Routes>
    </div>
  )
}

export default () => <App />
