import tw from 'twin.macro'
import '../../../App.css'
import { useState } from 'react'
import { Header } from '../components'
import { useNavigate } from 'react-router-dom'

interface VenueInfo {}

export const ConfigureRoute = () => {
  const navigate = useNavigate()
  const [domain, setDomain] = useState('')
  const [value, setValue] = useState('')

  const onChange = e => {
    if (e.target.value.slice(-1) === '.') {
      setDomain(e.target.value + 'ticketbrosandbox.io')
      setValue(e.target.value.substring(0, e.target.value.length - 1))
    } else {
      setDomain(e.target.value)
      setValue(e.target.value)
    }
  }

  const submit = () => {
    navigate('/dashboard/' + value + '/overview')
  }

  return (
    <div tw="w-screen h-screen flex justify-content[flex-start] flex-direction[column] background-color[#F0F2F4]">
      <Header />
      <div tw="w-full h-full justify-center flex items-center">
        <div tw="bg-white shadow-lg border-radius[24px] p-12">
          <div tw="sm:col-span-2">
            <label
              htmlFor="company"
              tw="block text-sm font-medium text-gray-700"
            >
              Enter your domain
            </label>
            <div tw="mt-1">
              <input
                value={domain}
                onChange={onChange}
                type="text"
                name="company"
                id="company"
                autoComplete="organization"
                tw="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              />
            </div>
            <button
              type="submit"
              onClick={submit}
              tw="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-10"
            >
              Let's go
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
