import tw from 'twin.macro'
import dayjs from 'dayjs'

export const Header = ({ venue }) => {
  return (
    <header tw="shadow-lg mb-2">
      <nav tw="mx-auto px-4 sm:px-6 lg:px-8 bg-white" aria-label="Top">
        <div tw="w-full py-6 flex items-center justify-between border-b lg:border-none">
          <div tw="flex items-center">
            <a href="#">
              <span tw="sr-only">Workflow</span>
              <img tw="h-10 w-auto" src={venue?.logo} alt="" />
            </a>
            <p tw="inline-block py-2 px-4 text-xl border color[#334155] border-transparent rounded-md  font-bold">
              {venue?.name}
            </p>
          </div>
          <div tw="font-bold items-center absolute left[45%]">
            <p tw="font-light text-sm color[#94A3B8] text-center">
              Live-Capacity
            </p>
            <p tw="font-bold text-xl color[#334155] text-center">
              {dayjs().format('MMM DD, YYYY')}
            </p>
          </div>
          <div></div>
        </div>
      </nav>
    </header>
  )
}
