import tw from 'twin.macro'
import { Slot } from './slot'

export const CurrentSlot = ({ slot, fullday }) => {
  const height = (slot?.availableQuantity / slot?.maxQuantity) * 100

  return (
    <div tw="shadow-lg  border-right-width[2px] height[100%] bg-white border-radius[24px] flex flex-col align-items[center] justify-center">
      <span
        css={[
          height <= 15
            ? tw`bg-default-red-500`
            : height <= 35
            ? tw`bg-default-yellow-500`
            : tw`bg-default-green-500`,
        ]}
        tw="width[100px] height[10px] relative top[0px] border-bottom-left-radius[5px] border-bottom-right-radius[5px]"
      ></span>
      <p
        css={[
          height <= 15
            ? tw`text-default-red-500`
            : height <= 35
            ? tw`text-default-yellow-500`
            : tw`text-default-green-500`,
        ]}
        tw="inline-block border border-transparent rounded-md  text-lg font-family[bold] mt-2"
      >
        {fullday ? 'Full Day' : 'Now'}
      </p>
      <Slot slot={slot} current={true} />
    </div>
  )
}
