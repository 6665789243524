import tw from 'twin.macro'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { ViewBoardsIcon } from '@heroicons/react/solid'
import { fetchAvailability } from '../utility'
import { Slot } from './slot'
import { CurrentSlot } from './current-slot'
import { EmptySlot } from './empty-slot'

export const SingleTicketView = ({ venueInfo, ticketId }) => {
  const [ticketInfo, setTicketInfo] = useState([])
  const [currentSlot, setCurrentSlot] = useState()
  const [isFullDay, setFullDay] = useState(false)
  const [currentTicketInfo, setCurrentTicketInfo] = useState()

  const getTicketInfo = async () => {
    const { isFullDay, remainingSlots, currentSlot } = await fetchAvailability(
      venueInfo.id,
      ticketId,
    )
    setCurrentTicketInfo(
      venueInfo.liveDashboard.tickets.filter(({ id }) => id === ticketId)[0],
    )
    setTicketInfo(remainingSlots)
    setCurrentSlot(currentSlot)
    setFullDay(isFullDay)
  }

  useEffect(() => {
    getTicketInfo()
  }, [])

  return (
    <div tw="w-full h-full flex items-center justify-center p-12">
      <div tw="w-full h-full border-radius[24px] shadow-lg bg-white flex justify-start items-center flex-col overflow-hidden">
        <div
          style={{ backgroundImage: `url(${venueInfo.banner})` }}
          tw="w-full height[30%] background-size[cover] background-repeat[no-repeat]"
        >
          <div tw="position[relative] top[30%] left[50px] flex flex-row items-center">
            {currentTicketInfo?.image ? (
              <img
                src={currentTicketInfo?.image}
                tw="bg-blueGray-200 width[200px] height[200px] border-radius[12px] object-cover"
              />
            ) : (
              <div tw="background-color[#040FD9] flex justify-center items-center width[200px] height[200px] border-radius[12px] object-cover">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  tw="h-24 w-24"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"
                  />
                </svg>
              </div>
            )}
            <div tw="flex flex-col ml-10">
              <p tw="font-weight[800] color[white] text-5xl">
                {currentTicketInfo?.name}
              </p>
              <div tw="flex flex-row items-center">
                <ViewBoardsIcon tw="h-6 w-6 fill[white]" aria-hidden="true" />
                <p tw="inline-block py-2 px-0.5 border border-transparent rounded-md color[white] text-sm text-base font-family[light]">
                  Open Slots
                </p>
              </div>
              <div>
                <div tw="flex flex-row">
                  {ticketInfo?.available ? (
                    <span tw="animate-pulse px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full flex flex-row items-center ">
                      <div tw="bg-green-500 height[15px] width[15px] border-radius[8px] mr-2 border-default-green-300 border-width[3px]"></div>
                      <p tw="inline-block border border-transparent text-green-700 rounded-md text-sm font-family[regular]">
                        Open
                      </p>
                    </span>
                  ) : (
                    <span tw="px-2 py-0.5 text-red-800 text-xs font-medium bg-red-100 rounded-full flex flex-row items-center ">
                      <div tw="animate-pulse bg-red-500 height[15px] width[15px] border-radius[8px] mr-2 border-red-300 border-width[3px]"></div>
                      <p tw="inline-block border border-transparent rounded-md color[#94A3B8] text-sm font-family[light]">
                        CLOSED
                      </p>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div tw="height[70%] flex justify-start flex-row items-end overflow-hidden width[100%]">
          <div tw="flex height[80%] justify-start flex-row items-start overflow-hidden width[100%] p-12">
            {currentSlot ? (
              <CurrentSlot fullday={isFullDay} slot={currentSlot} />
            ) : (
              <EmptySlot />
            )}
            {ticketInfo?.slots?.map(slot => {
              return <Slot slot={slot} />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
