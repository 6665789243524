import tw from 'twin.macro'
import dayjs from 'dayjs'
import { ChevronRightIcon } from '@heroicons/react/solid'

export const Slot = ({ slot, current }) => {
  const height = (slot?.availableQuantity / slot?.maxQuantity) * 100

  return (
    <div tw="color[#475569] pl-8 pr-8 height[100%] flex flex-col items-center font-family[Inter]">
      {!current && (
        <div tw="flex flex-row items-center justify-between mb-3 mt-3">
          <p tw="font-family[regular] color[#475569]">
            {dayjs(slot?.start).format('HH:mm')}
          </p>
          <ChevronRightIcon tw="h-6 w-6" aria-hidden="true" />
          <p tw="font-family[regular] color[#475569]">
            {dayjs(slot?.end).format('HH:mm')}
          </p>
        </div>
      )}
      <div
        css={[
          height <= 15
            ? tw`bg-default-red-100 border-default-red-200`
            : height <= 35
            ? tw`bg-default-yellow-100 border-default-yellow-200`
            : tw`bg-default-green-100 border-default-green-200`,
        ]}
        tw="width[200px] height[80%] border-radius[12px] border-2 flex flex-col justify-end"
      >
        <div
          style={{ height: height + '%' }}
          css={[
            height <= 15
              ? tw`from-default-red-500 to-default-red-400`
              : height <= 35
              ? tw`from-default-yellow-500 to-default-yellow-400`
              : tw`from-default-green-500 to-default-green-400`,
          ]}
          tw="relative top-0 bg-gradient-to-t  left-0 w-full border-radius[12px]"
        ></div>
      </div>
      <div tw="flex flex-row items-end justify-between mt-3 mb-3">
        <p
          css={[
            height <= 15
              ? tw`text-default-red-400`
              : height <= 35
              ? tw`text-default-yellow-400`
              : tw`text-default-green-400`,
          ]}
          tw="font-family[bold] text-xl  mr-1"
        >
          {slot?.availableQuantity}
        </p>
        <p tw="font-family[regular] color[#94A3B8]  ml-1">/</p>
        <p tw="font-family[regular] color[#94A3B8]  ml-1">
          {slot?.maxQuantity} left
        </p>
      </div>
    </div>
  )
}
